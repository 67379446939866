import React from 'react'
import styled from 'styled-components'

import { Grid, H, Image, P, Wrapper } from '@farewill/ui'
import { BORDER, FONT, GTR } from '@farewill/ui/tokens'
import { CLOUDINARY_ROOT_PATH } from '@farewill/ui/components/Image/constants'
import { FormatPathArgs } from '@farewill/ui/components/Image/types'
import { screenMin } from '@farewill/ui/helpers/responsive'
import { headingL, headingS } from '@farewill/ui/helpers/text'

import ScrollingCharitiesList from 'components/ScrollingCharitiesList'
import CallToAction from '../CallToAction'

const StyledH = styled(H)`
  ${headingS}
  font-weight: ${FONT.WEIGHT.REGULAR};

  ${screenMin.l`
    ${headingL}
  `}
`

const StyledGrid = styled(Grid)`
  gap: ${GTR.L} 0;

  ${screenMin.l`
    column-gap: ${GTR.XL};
  `}
`

const StyledGridItem = styled(Grid.Item)`
  --gap: ${GTR.S};

  display: flex;
  gap: var(--gap);
  flex-direction: column-reverse;

  ${screenMin.l`
    --gap: ${GTR.M};
    flex-direction: column;
  `}
`

const StyledCharitiesListWrapper = styled(Wrapper)`
  border-radius: ${BORDER.RADIUS.XL}
  box-shadow: ${BORDER.SHADOW.M};
`

const StyledCharitiesListHeading = styled(H)`
  font-weight: ${FONT.WEIGHT.MEDIUM};
`

const Column = ({
  description,
  image,
}: {
  description: string
  image: string
}) => (
  <StyledGridItem spanFromL={4}>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <Image
      height="48"
      path={image}
      formatPath={({ path, ext }: FormatPathArgs) =>
        `${CLOUDINARY_ROOT_PATH}/c_scale,f_auto,q_auto,h_96/${path}.${ext}`
      }
    />
    <P margin={0}>“{description}”</P>
  </StyledGridItem>
)

const Partners = (): React.ReactElement => (
  <Wrapper container>
    <StyledH
      centeredFromL
      decorative
      margin={[0, 0, GTR.L]}
      marginFromL={[0, 0, GTR.XXL]}
    >
      We work with over 300 charities of all shapes and sizes across the UK
    </StyledH>

    <StyledGrid margin={[0, 0, GTR.L]} marginFromL={[0, 0, GTR.XXL]}>
      <Column
        image="home-page-charity-logos/Battersea"
        description="Over 40% of Battersea’s work is funded by gifts in Wills so our partnership with Farewill plays a hugely important role in securing future Legacy income for Battersea, allowing us to continue being here for every dog and cat who needs us."
      />
      <Column
        image="home-page-charity-logos/british-heart-foundation"
        description="Gifts in Wills funds over half of BHF funded research. The support from our partnership with Farewill has helped generate close to £34 million estimated future income, which could help fund 34 five-year research programmes into  heart and circulatory disease."
      />
      <Column
        image="home-page-charity-logos/surfers-against-sewage"
        description="Farewill’s platform is such a powerful tool for us. It’s really helped us expand our legacy income, and offer supporters  a new way to support our work. The free will package allows  us to give back to our members, while also helping us protect the ocean for decades to come."
      />
    </StyledGrid>

    <Wrapper centered>
      <CallToAction trackingId="partners-slice">
        Partner with Farewill
      </CallToAction>
    </Wrapper>

    <StyledCharitiesListWrapper
      bordered
      margin={[GTR.L, 0, 0]}
      marginFromL={[GTR.XXL, 0, 0]}
      padding={[GTR.M, 0]}
      paddingFromL={[GTR.L, 0]}
    >
      <Wrapper
        padding={[0, GTR.L]}
        paddingFromL={[0, GTR.L]}
        margin={[0, 0, GTR.L]}
      >
        <StyledCharitiesListHeading centered margin={[0, 0, GTR.L]} size="S">
          We partner with charities in every cause area
        </StyledCharitiesListHeading>
      </Wrapper>

      <ScrollingCharitiesList />
    </StyledCharitiesListWrapper>
  </Wrapper>
)

export default Partners
